@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

.small-dialog {
  header,
  footer {
    position: sticky;
    z-index: 1;
  }
  header {
    top: 0;
    box-shadow: $shadow-3;
  }
  footer {
    bottom: -0.5px;
    box-shadow: $shadow-up-3;
  }
  .dialog-content,
  footer {
    color: $dark;
    background-color: #fff;
    body.body--dark & {
      color: #fff;
      background-color: $dark;
    }
  }
}
