$primary: #8bc34a;
$secondary: #607d8b;
$bg: #607d8b;
$ui: #263238;
$accent: #37474f;
$panel: rgba(#78909c, 0.75);
$panelOpaque: rgba($panel, 1);
$panelOpaqueHover: rgba(mix($panel, rgba(#fff, 0.15)), 1);
$panelClear: rgba($panel, 0);
$panelClearHover: rgba(mix($panel, rgba(#fff, 0.15)), 0);
$board1: #8a9faa;
$board2: #90a4ae;
$board3: #78909c;
$player1: #cfd8dc;
$player1clear: rgba($player1, 0);
$player1road: #cfd8dc;
$player1flat: #cfd8dc;
$player1special: #eceff1;
$player1border: #546e7a;
$player2: #263238;
$player2clear: rgba($player2, 0);
$player2road: #455a64;
$player2flat: #546e7a;
$player2special: #455a64;
$player2border: #263238;
$textLight: rgba(#fafafa, 0.8);
$textDark: rgba(#212121, 0.8);
$umbra: rgba(0, 0, 0, 0.2);

$piece-border-width: 1;
$rings-opacity: 0.25;
$footer-toolbar-height: 58px;

$positive: #8bc34a;
$negative: #c10015;
$info: #2196f3;
$warning: #ffc107;

$red-light: #ef5451;
$red-dark: #e53a35;
$orange-light: #faa629;
$orange-dark: #b57620;
$green-light: #9ccc65;
$green-dark: #378e43;
$blue-light: #68c1cd;
$blue-dark: #1b87c9;
$highlight: rgba(#fff, 0.1);
$dim: rgba(#000, 0.1);

$time: 0.3s;
$half-time: $time / 2;
$one-third-time: $time / 3;
$two-thirds-time: $time * 2 / 3;
$easing: cubic-bezier(0.25, 0.8, 0.5, 1);
$easing-reverse: cubic-bezier(0.5, 0, 0.75, 0.2);
